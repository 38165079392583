import { useState, useEffect, useCallback, useMemo } from 'react';
import CustomButton from '@/components/CustomButton/CustomButton';
import styles from './styles.module.css';
import SelectionBox from './SelectionBox';
import LogoContainerLayout from '../Layout/LogoContainerLayout';
import masquerading from '@/service/masquerading';
import { Tenant, Subject } from '@/types/tenantSubjectTypes';
// eslint-disable-next-line
// @ts-ignore
import { t } from '@sales-i/utils';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import Cookies from 'universal-cookie';

export default function Masquerading() {
  const [loginOrMasquerade, setLoginOrMasquerade] = useState(false);
  const [showTenants, setShowTenants] = useState(false);
  const [tenants, setTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState<Tenant>({});
  const [loading, setLoading] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState<Subject>({});
  const [selectedTenantId, setSelectedTenantId] = useState('');
  const [isSelectionMade, setIsSelectionMade] = useState(false);

  const cookies = useMemo(() => new Cookies(null, { path: '/' }), []);

  const login = useCallback(async() => {
    setLoading(true);
    try {
      if (selectedSubject.id) {
        await masquerading.masquerade(selectedSubject.id, selectedSubject.email);
      } else if (selectedTenant.tenant_id) {
        await masquerading.tenantLogin(selectedTenant.tenant_id);
      }
      const redirectTo = cookies.get('redirect-url');
      cookies.remove('redirect-url');
      window.location.replace(redirectTo);
    } catch (e) {
      setLoading(false);
    }
  }, [selectedSubject.id, selectedSubject.email, selectedTenant.tenant_id, cookies]);

  const init = useCallback(async() => {
    await masquerading.getUserDetails();
    const fetchedTenants = await getTenants();
    if ('id' in selectedSubject === false && fetchedTenants.length === 1) {
      const fetchedTenant = fetchedTenants[0];
      if (!fetchedTenant.permissions.can_masquerade) {
        await masquerading.tenantLogin(fetchedTenant.tenant_id);
        login();
        return;
      } else {
        setLoginOrMasquerade(true);
      }
      setSelectedTenant(fetchedTenant);
      getSubjects();
    }
    setShowTenants(true);
  }, [login]);

  const getTenants = async() => {
    const data = await masquerading.getTenants();
    setTenants(data);
    return data;
  };

  const getSubjects = async() => {
    setLoading(true);
    const data = await masquerading.getSubjects();
    setSubjects(data);
    setLoading(false);
  };

  const handleTenantSelection = (event) => {
    const tenantId = event.target.value;
    setSelectedTenantId(tenantId);
    setIsSelectionMade(true);
  };

  const applySelectedTenant = async () => {
    const foundTenant = tenants.find((tenant) => tenant.tenant_id === selectedTenantId);
    setSelectedTenant(foundTenant);
    setSelectedSubject({});
    setIsSelectionMade(false);
    if (foundTenant.permissions.can_masquerade) {
      setLoginOrMasquerade(true);
      getSubjects();
    } else {
      setLoading(true);
      await masquerading.tenantLogin(selectedTenantId);
      login();
    }
  };
  
  const handleSubjectSelection = (event) => {
    const subjectId = event.target.value;
    const foundSubject = subjects.find(subject => subject.id === subjectId);
    setSelectedSubject(foundSubject);
  };

  const auth0Callback = useCallback(async() => {
    const auth0 = await createAuth0Client({
      domain: 'poc.eu.auth0.com',
      clientId: 'ZlnzLPRhe71X7KXv1MN1ivejuDvmuJxo',
      authorizationParams: {
        redirect_uri: 'http://localhost:8080/masquerading'
      }
    });
    if (location.search.includes('state=') && 
      (location.search.includes('code=') || 
      location.search.includes('error='))) {
      await auth0.handleRedirectCallback();
      window.history.replaceState({}, document.title, '/masquerading');
      const claims = await auth0.getIdTokenClaims();
      cookies.set('token', claims.__raw);
    }
    init();
  }, [init, cookies]);

  useEffect(() => {
    auth0Callback();
  }, [auth0Callback]);

  return (
    <LogoContainerLayout loading={!loginOrMasquerade && (!showTenants || loading)}>
      {
        loginOrMasquerade ? (
          <div className={styles.masqueradeSelection}>
            <CustomButton
              label="Login"
              type="submit"
              onClick={login}
            />
            {t('OR')}
            <CustomButton
              label="Masquerade"
              type="submit"
              onClick={() => setLoginOrMasquerade(false)}
            />
          </div>
        ) : (
          <div>
            {
              showTenants && tenants?.length > 1 && (
                <>
                  <label htmlFor="tenant">
                    {t('Select Tenant')}
                  </label>
                  <SelectionBox
                    list={tenants}
                    list-name="tenants"
                    selectId="tenant"
                    value={selectedTenantId}
                    valueProperty="tenant_id"
                    displayProperty="name"
                    handleInput={handleTenantSelection}
                  />
                  {
                    isSelectionMade && (
                      <div className={styles.submitBtn}>
                        <CustomButton
                          label="Select tenant"
                          type="submit"
                          onClick={applySelectedTenant}
                        />
                      </div>
                    )
                  }
                </>
              )
            }
            { selectedTenant.tenant_id && !loading && (
              <label htmlFor="subject">
                {t('Select Subject')}
              </label>
            )
            }
            {
              selectedTenant.tenant_id && !loading && (
                <SelectionBox
                  list={subjects}
                  list-name="subjects"
                  selectId="subject"
                  value={selectedSubject.id}
                  valueProperty="id"
                  displayProperty="full_name"
                  handleInput={handleSubjectSelection}
                />
              )
            }
            {
              selectedSubject.id && (
                <div className={styles.submitBtn}>
                  <CustomButton
                    label={t('Login')}
                    type="submit"
                    onClick={login}
                  />
                </div>
              )
            }
          </div>
        )
      }
    </LogoContainerLayout>
  );
}
