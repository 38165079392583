import { useState, useEffect } from 'react';
// eslint-disable-next-line
// @ts-ignore
import { t } from '@sales-i/utils';
import Cookies from 'universal-cookie';
import styles from './styles.module.css';

const cookies = new Cookies(null, { path: '/' });

/* eslint-disable no-useless-escape */ // because of ¯\_(ツ)_/¯
export default function RememberMeCheckbox({ email, password, setEmail, setPassword }) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const storedValues = cookies.get('remember-me');
    if (storedValues) {
      const [storedEmail, storedPassword] = storedValues.split('¯\_(ツ)_/¯');
      setChecked(true);
      setEmail(storedEmail);
      setPassword(storedPassword);
    }
  }, [setEmail, setPassword]);

  useEffect(() => {
    if (checked) {
      cookies.set('remember-me', `${email}¯\_(ツ)_/¯${password}`);
    } else {
      cookies.remove('remember-me');
    }
  }, [email, password, checked]);

  const handleInput = () => {
    setChecked(oldVal => !oldVal);
  };

  const handleKeydown = (e) => {
    const runEvent = e.code === 'Space' || e.code === 'Enter';
    if (runEvent) {
      e.preventDefault();
      handleInput();
    }
  };

  return (
    <label
      className={styles.checkbox}
      tabIndex={-1}
    >
      <div
        className={styles.checkboxContainer}
        tabIndex={-1}
      >
        <input
          type="checkbox"
          checked={checked}
          tabIndex={-1}
          onChange={handleInput}
        />
        <div
          className={styles.check}
          tabIndex={0}
          onKeyDown={handleKeydown}
          role="checkbox"
          aria-checked={checked}
        />
        {t('Remember me')}
      </div>
    </label>
  );
}
