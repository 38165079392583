import styles from './styles.module.css';

type buttonTypes = 'button' | 'submit' | 'reset';
type purposeTypes = 'action' | 'neutral' | 'reversed' | 'transparent';

interface ButtonProps {
  label: string,
  type?: buttonTypes,
  purpose?: purposeTypes,
  onClick?: () => void,
  disabled?: boolean
}

export default function Button({ label, type = 'button', purpose = 'action', onClick = () => {}, disabled = false }: ButtonProps) {
  return (
    <button
      className={`${styles.button} ${styles[purpose]}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
