import { useState, useEffect } from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
// eslint-disable-next-line
// @ts-ignore
import { isLoggedInState } from '@sales-i/utils';
import LoginForm from './components/LoginForm/LoginForm';
import Loading from './components/Loading/Loading';
import Masquerading from './components/Masquerading/Masquerading';

const router = createBrowserRouter([
  {
    path: '/*',
    element: <LoginForm />,
  },
  {
    path: '/masquerading',
    element: <Masquerading />
  }
]);

export default function App() {
  const [loggedIn, setLoggedIn] = useState(null);
  useEffect(() => {
    const ilsrx = isLoggedInState.subscribe(l => setLoggedIn(l));
    return () => { ilsrx.unsubscribe(); };
  }, [loggedIn]);
  if (loggedIn) { return <></>; }
  if (loggedIn !== false) return <Loading />;
  return <RouterProvider router={router} />;
}
